import { post } from '@/utils/request';


// 退货统计-获取退货统计默认或设置项
const getStatistical = (data) => post({
    url: '/store/statistical.refund/getStatistical',
    data: data
});

// 退货统计-编辑退货统计表
const editStatistical = (data) => post({
    url: '/store/statistical.refund/editStatistical',
    data: data
})


// 退货统计-新增退货统计表
const addStatistical = (data) => post({
    url: '/store/statistical.refund/addStatistical',
    data: data
})

// 退货统计-获取退货统计表列表
const getStatisticalList = (data) => post({
    url: '/store/statistical.refund/getStatisticalList',
    data: data
})

// 退货统计-获取统计维度数据
const getTotalStatisticalData = (data) => post({
    url: '/store/statistical.refund/totalStatisticalData',
    data: data
})

// 退货统计-获取雷达图数据
const getTotalStatisticalRadar = (data) => post({
    url: '/store/statistical.refund/totalStatisticalRadar',
    data: data
})

// 退货统计-时间轴数据
const getTotalStatisticalTime = (data) => post({
    url: '/store/statistical.refund/totalStatisticalTime',
    data: data
})

// 明细
const getTotalStatisticalList = (data) => post({
    url: '/store/statistical.refund/totalStatisticalList',
    data: data
})

const getAttrValueList = (data) => post({
    url: '/store/common.select/getAttrValueList',
    data: data
})

const getMaterialListReq = () => post({
    url: '/store/common.Select/getMaterialList',
});

// 导出
const downloadList = (data) => post({
    url: '/store/statistical.refund/downloadList',
    responseType: 'blob',
    data: data
})

// 删除
const deleteStatistical = (data) => post({
    url: '/store/statistical.refund/deleteStatistical',
    data: data
})
// 数据分析
const totalStatisticalData2D = (data) => post({
    url: '/store/statistical.refund/totalStatisticalData2D',
    data: data
})

// 数据分析-导出
const totalStatisticalData2DDownload = (data) => post({
    url: '/store/statistical.refund/totalStatisticalData2DDownload',
    responseType: 'blob',
    data: data
})


export {
    getStatistical,
    editStatistical,
    addStatistical,
    getStatisticalList,
    getTotalStatisticalData,
    getTotalStatisticalRadar,
    getTotalStatisticalTime,
    getTotalStatisticalList,
    downloadList,
    deleteStatistical,
    getAttrValueList,
    getMaterialListReq,
    totalStatisticalData2D,
    totalStatisticalData2DDownload
}
