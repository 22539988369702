<template>
  <div>
    <div class="wrapper" v-if="!isAdd && !isDetails">
      <div class="content">
        <div class="card">
          <el-page-header @back="goBack" :content="info.name"> </el-page-header>
        </div>
        <div
          style="
            display: flex;
            padding: 11px 0 11px 21px;
            border: 1px solid #e8e8e8;
            border-top: none;
            border-radius: 0 0 7px 7px;
          "
        >
          <div class="left">
            <!-- <el-button type="primary">打印统计</el-button> -->
            <el-button
              type="primary"
              @click="
                () => {
                  isAdd = true;
                }
              "
              >编辑统计</el-button
            >
          </div>
          <div class="line"></div>
          <div class="middle">
            <el-form :model="cond" class="middle" label-width="100px">
              <el-form-item label="统计门店">
                <el-select
                  v-model="cond.hierarchy_id"
                  placeholder="请选择统计门店"
                  @change="handleAllSearch"
                >
                  <template v-for="(item, index) in storeList">
                    <el-option
                      :label="item.name"
                      :value="String(item.hierarchy_id)"
                      :key="index"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="退货时间">
                <el-date-picker
                  v-if="time_type === 'year'"
                  v-model="cond.day_time"
                  type="year"
                  value-format="timestamp"
                  placeholder="选择年"
                  @change="handleAllSearch"
                >
                </el-date-picker>
                <el-date-picker
                  v-if="time_type === 'month'"
                  v-model="cond.day_time"
                  type="month"
                  value-format="timestamp"
                  placeholder="选择月"
                  @change="handleAllSearch"
                >
                </el-date-picker>
                <el-date-picker
                  v-if="time_type === 'day'"
                  v-model="cond.day_time"
                  type="date"
                  placeholder="选择天"
                  value-format="timestamp"
                  @change="handleAllSearch"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div class="left" style="margin-left: 20px">
            <el-button
              type="primary"
              @click="
                () => {
                  isDetails = true;
                }
              "
              >数据分析</el-button
            >
          </div>
        </div>
      </div>
      <div
        class="content"
        style="border: 1px solid #e8e8e8; border-radius: 7px; margin-top: 10px"
      >
        <div style="padding-left: 18px; margin-top: 15px">
          <el-radio-group
            v-model="tabPosition"
            style="margin-bottom: 15px"
            @change="handleDimensionType"
          >
            <template v-for="(item, index) in dimension">
              <el-radio-button
                :label="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
                >{{ item.attr_name }}</el-radio-button
              >
            </template>
          </el-radio-group>
          <el-button
            type="primary"
            style="float: right; margin-right: 15px"
            :loading="exportLoading"
            @click="handleExport"
            >导出</el-button
          >
          <el-button
            type="primary"
            style="float: right; margin-right: 10px"
            @click="
              () => {
                localPrintVisible = true;
              }
            "
            >打印</el-button
          >

          <el-button
            type="primary"
            style="float: right"
            @click="
              () => {
                statistVisiable = true;
              }
            "
            >打印配置</el-button
          >
        </div>
        <div class="tab">
          <el-tabs
            v-model="statisticalAttr_id"
            style="height: 200px; width: 90%"
            @tab-click="handleStatisticType"
          >
            <el-tab-pane label="仅一级维度" name="0"></el-tab-pane>
            <template v-for="(item, index) in statistical_header">
              <el-tab-pane
                :label="item.attr_name"
                :name="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
              ></el-tab-pane>
            </template>
          </el-tabs>
        </div>
        <div
          class="table other"
          style="margin: 15px; border-top: 1px solid #e8e8e8"
          v-if="header.length !== 0"
        >
          <el-table
            :data="statisticalData"
            stripe
            max-height="630"
            v-loading="loading"
          >
            <template v-for="(item, index) in header">
              <el-table-column
                :key="index"
                :prop="item.header_id"
                :label="item.name"
                align="center"
                :width="header.length > 9 ? '160' : null"
              >
                <template slot-scope="scope">
                  <div
                    :style="
                      scope.row[tabPosition] === '(总计)' ||
                      scope.row[tabPosition] === '(小计)' ||
                      scope.row[tabPosition] === '(合计)'
                        ? 'font-weight: 600;color: rgb(33, 188, 54);'
                        : null
                    "
                  >
                    <div
                      v-if="
                        scope.row[item.header_id] === '货品统计' ||
                        scope.row[item.header_id] === '旧料统计'
                      "
                      style="font-size: 22px; color: black; margin: 10px 0"
                    >
                      {{ scope.row[item.header_id] }}
                    </div>
                    <div v-else>{{ scope.row[item.header_id] }}</div>
                  </div>
                </template>
              </el-table-column>
            </template>
            <el-table-column
              prop="action"
              fixed="right"
              label="操作"
              align="center"
              width="190"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleCheck(scope.row)"
                  v-if="
                    scope.row[tabPosition] !== '(总计)' &&
                    scope.row[tabPosition] !== '(小计)' &&
                    scope.row[tabPosition] !== '(合计)' &&
                    scope.row[tabPosition] !== '货品统计' &&
                    scope.row[tabPosition] !== '旧料统计'
                  "
                  >查看</el-button
                >
                <el-button
                  type="text"
                  @click="handleGoodsExport(scope.row)"
                  v-if="
                    scope.row[tabPosition] !== '(总计)' &&
                    scope.row[tabPosition] !== '(小计)' &&
                    scope.row[tabPosition] !== '(合计)' &&
                    scope.row[tabPosition] !== '货品统计' &&
                    scope.row[tabPosition] !== '旧料统计'
                  "
                  >导出明细</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        class="content"
        style="border: 1px solid #e8e8e8; border-radius: 7px; margin-top: 10px"
      >
        <div class="trabsTitle">
          <div class="trabsSpan"></div>
          退货明细
          <el-button
            type="primary"
            style="margin: 5px 18px 0 15px; height: 40px; line-heigth: 40px"
            @click="handleLoad"
            >导出</el-button
          >
        </div>
        <!-- <div class="tab">
          <div
            class="tabBox"
            :style="detaildedType === '' ? 'color: #2791ff;' : ''"
            @click="handleDetailGoodsType('')"
          >
            全部
            <div class="label" v-if="detaildedType === ''"></div>
          </div>
          <div
            class="tabBox"
            :style="detaildedType === 10 ? 'color: #2791ff;' : ''"
            @click="handleDetailGoodsType(10)"
          >
            货品
            <div class="label" v-if="detaildedType === 10"></div>
          </div>
          <div
            class="tabBox"
            :style="detaildedType === 20 ? 'color: #2791ff;' : ''"
            @click="handleDetailGoodsType(20)"
          >
            旧料
            <div class="label" v-if="detaildedType === 20"></div>
          </div>
        </div> -->
        <div
          class="card"
          style="margin: 0px 18px 0 18px; border-radius: 0px !important"
        >
          <el-form :model="detaildedForm">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="品类"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <SelectTree
                    id="select"
                    :props="props"
                    :options="goodsTypeList"
                    label1="商品分类"
                    :clearable="isClearable"
                    :accordion="isAccordion"
                    @getValue="getValue($event)"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="供应商"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm.supplier_id"
                    placeholder="请选择供应商"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in supplierList">
                      <el-option
                        :label="item.supplier_name"
                        :value="item.supplier_id"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="仓库"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm.warehouse_id"
                    placeholder="请选择仓库"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in wareHouseList">
                      <el-option
                        :label="item.warehouse_name"
                        :value="item.warehouse_id"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="款式"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm[goodsStyleKey]"
                    placeholder="请选择款式"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in goodsStyleList">
                      <el-option
                        :label="item.value"
                        :value="item.value"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="品牌"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm[goodsBrandKey]"
                    placeholder="请选择品牌"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in goodsBrandList">
                      <el-option
                        :label="item.value"
                        :value="item.value"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="材质成色"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm[materialKey]"
                    placeholder="请选择材质成色"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in materialList">
                      <el-option
                        :label="item.attr_value"
                        :value="item.attr_value"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="table" style="margin: 0 18px">
          <el-table
            :data="detailedList"
            stripe
            max-height="700"
            v-loading="tableLoading"
            :summary-method="getNowSummaries"
            show-summary
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="80"
            ></el-table-column>
            <template v-for="(item, index) in detailedHead">
              <el-table-column
                :key="index"
                :prop="item.key"
                :label="item.name"
                align="center"
                width="210"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row[item.key] || "--" }}</div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <div style="padding: 15px 0; display: flex; justify-content: flex-end">
          <el-pagination
            :current-page="detaildedForm.page"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="detaildedTotal"
            :page-size="$store.state.pageSizes[0]"
            :page-sizes="$store.state.pageSizes"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <Add
      v-if="isAdd"
      @hide="getStatistical"
      :refund_statistical_id="refund_statistical_id"
    />
    <TransferData
      v-if="isDetails"
      @hide="getStatistical"
      :refund_statistical_id="refund_statistical_id"
    />
    <LocalPrint
      :show="false"
      :printRows="list_header"
      :printData="printData"
      title="退货统计"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
    <el-dialog
      :fullscreen="false"
      title="设置统计表头"
      :visible.sync="statistVisiable"
    >
      <div class="table" style="border-top: 1px solid #e8e8e8">
        <el-table :data="statistical_set_header" stripe :height="600">
          <el-table-column
            prop="attr_name"
            label="统计表头"
            align="center"
          ></el-table-column>
          <el-table-column prop="width" label="宽度(mm)" align="center">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.width"
                style="width: 100px; margin: 3px 0"
              />
              mm
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin-top: 10px">
        <el-button
          @click="
            () => {
              statistVisiable = false;
            }
          "
          >取消</el-button
        >
        <el-button type="primary" @click="handleSetStatisticalHead"
          >确定</el-button
        >
      </div></el-dialog
    >
    <el-dialog
      title="退货统计明细"
      :visible.sync="goodsDetailVisiable"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="cancelGoodsDetail"
      width="80%"
    >
      <div>
        <div
          class="table"
          style="margin: 0 18px; border: 1px solid #e8e8e8"
          v-if="goodsDetailHead.length !== 0"
        >
          <el-table
            :data="goodsDetailList"
            stripe
            max-height="700"
            v-loading="detailTableLoading"
            :summary-method="getNowSummariesDetails"
            show-summary
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="80"
            ></el-table-column>
            <template v-for="(item, index) in goodsDetailHead">
              <el-table-column
                :key="index"
                :prop="item.key"
                :label="item.name"
                align="center"
                width="210"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row[item.key] || "--" }}</div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <div style="padding: 15px 0; display: flex; justify-content: flex-end">
          <el-pagination
            :current-page="goodsDetailForm.page"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="goodsDetailTotal"
            :page-size="$store.state.pageSizes[0]"
            :page-sizes="$store.state.pageSizes"
            @current-change="handleCurrentChange_check"
            @size-change="handleSizeChange_check"
          ></el-pagination>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="提示：导出数据过大，需要分批导出"
      :visible.sync="exportVisiable"
      width="25%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="cancelExport"
    >
      <div
        v-for="(item, index) in exportList"
        :key="index"
        style="
          margin: 0 auto 10px auto;
          display: flex;
          width: 330px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          border: 1px solid #e8e8ee;
          border-radius: 5px;
        "
      >
        <div style="width: 200px; text-align: center">第{{ item.text }}</div>
        <div>
          <el-button
            size="mini"
            type="primary"
            style="
              width: 80px;
              height: 30px;
              margin-left: 15px;
              margin-top: 5px;
              margin-bottom: 5px;
            "
            @click.stop="handleExportDetail(2, item, index)"
            :disabled="exportDisable"
            :loading="exportIndex === index + 1 ? exportLoading : false"
            >下载</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LocalPrint from "@/components/localPrint/Index.vue";
import { getHierarchyAll } from "@/api/index";
import {
  getStatistical,
  getTotalStatisticalList,
  totalStatisticalData2D,
  totalStatisticalData2DDownload,
  editStatistical,
  getAttrValueList,
  getMaterialListReq,
  downloadList,
} from "@/api/statistics/returnGoods/index.js";
import Add from "./Add.vue";
import TransferData from "./transferData.vue";
import storage from "good-storage";
import { getListReq as getTypeListReq } from "@/api/goods/goodsConfig/classify";
import { getSupplierList } from "@/api/goods/goodsWarehousing/warehousing";
import { getWarehouseListAll } from "@/api/statistics/transfer/index.js";

export default {
  data() {
    return {
      exportVisiable: false,
      exportList: [],
      exportDisable: false,
      exportLoading: false,
      exportIndex: 1,
      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      statistVisiable: false,
      isDetails: false,
      isAdd: false,
      info: {},
      edit_list_header: [],
      storeList: [],
      userInfo: {},
      time_type: "",
      cond: {
        day_time: 0,
        hierarchy_id: 0,
      },
      dimension: [],
      tabPosition: "",
      statistical_header: [],
      statisticalAttr_id: "0",
      statisticalData: [],
      header: [],
      exportLoading: false,
      loading: false,
      list_header: [],
      list_header_temporary: [],
      localPrintVisible: false,
      statistical_set_header: [],
      printData: {
        list: [],
      },
      detailedHead: [],
      detailedList: [],
      detailedLastList: [],
      detaildedForm: {
        page: 1,
        page_size: 50,
      },
      detaildedTotal: 0,
      detaildedType: "",
      supplierList: [],
      goodsStyleList: [],
      goodsStyleKey: 0,
      goodsBrandList: [],
      goodsBrandKey: 0,
      goodsTypeList: [],
      wareHouseList: [],
      materialList: [],
      materialKey: 0,
      tableLoading: false,
      goodsDetailVisiable: false,
      goodsDetailLastList: [],
      goodsDetailList: [],
      goodsDetailHead: [],
      goodsDetailTotal: 0,
      detailTableLoading: false,
      goodsDetailForm: {
        page: 1,
        page_size: 50,
        fixed: {},
        custom: {},
        goods_type: null,
      },
    };
  },
  props: {
    refund_statistical_id: Number,
  },
  components: {
    Add,
    TransferData,
    LocalPrint,
  },
  created() {
    this.userInfo = storage.get("userInfo");
    this.getMerchants();
    this.getStatistical();
    this.getTypeList();
    this.getSupplierList();
  },
  methods: {
    cancelGoodsDetail() {
      this.goodsDetailVisiable = false;
      this.goodsDetailForm = {
        page: 1,
        page_size: 50,
        fixed: {},
        custom: {},
        goods_type: null,
      };
      this.goodsDetailList = [];
      this.goodsDetailTotal = 0;
      this.goodsDetailHead = [];
      this.goodsDetailLastList = [];
    },
    handleCheck(row) {
      this.goodsDetailVisiable = true;
      const tab = this.tabPosition;

      if (Number(tab) + "" !== NaN + "") {
        this.goodsDetailForm.custom[this.tabPosition] =
          row["gg_" + this.tabPosition];
      } else {
        this.goodsDetailForm.fixed[this.tabPosition] =
          row["gg_" + this.tabPosition];
      }
      if (this.statisticalAttr_id !== "0") {
        const tab2 = this.statisticalAttr_id;
        if (Number(tab2) + "" !== NaN + "") {
          this.goodsDetailForm.custom[this.statisticalAttr_id] =
            row["gg_" + this.statisticalAttr_id];
        } else {
          this.goodsDetailForm.fixed[this.statisticalAttr_id] =
            row["gg_" + this.statisticalAttr_id];
        }
      }
      if (this.cond.hierarchy_id) {
        this.goodsDetailForm.hierarchy_id = this.cond.hierarchy_id;
      }
      this.goodsDetailForm.goods_type = row.goods_type;
      this.goodsDetailForm.refund_statistical_id = this.refund_statistical_id;
      this.goodsDetailForm.fixed = JSON.stringify(this.goodsDetailForm.fixed);
      this.goodsDetailForm.custom = JSON.stringify(this.goodsDetailForm.custom);
      this.handleCheackGoodsDetail();
    },
    handleCheackGoodsDetail() {
      this.detailTableLoading = true;
      getTotalStatisticalList({ ...this.goodsDetailForm }).then((res) => {
        if (res.code === 1) {
          if (res.data.list.length !== 0) {
            this.goodsDetailLastList = res.data.list.data.slice(-1);
            this.goodsDetailList = res.data.list.data.filter(
              (item) => item.refund_order_number !== "当前页合计"
            );
            this.goodsDetailHead = res.data.list.header;
          } else {
            this.goodsDetailList = [];
          }
          this.detailTableLoading = false;
          this.goodsDetailTotal = res.data.count;
        } else {
          this.detailTableLoading = false;
        }
      });
    },
    handleGoodsExport(row) {
      const tab = this.tabPosition;
      if (Number(tab) + "" !== NaN + "") {
        this.goodsDetailForm.custom[this.tabPosition] =
          row["gg_" + this.tabPosition];
      } else {
        this.goodsDetailForm.fixed[this.tabPosition] =
          row["gg_" + this.tabPosition];
      }
      if (this.statisticalAttr_id !== "0") {
        const tab2 = this.statisticalAttr_id;
        if (Number(tab2) + "" !== NaN + "") {
          this.goodsDetailForm.custom[this.statisticalAttr_id] =
            row["gg_" + this.statisticalAttr_id];
        } else {
          this.goodsDetailForm.fixed[this.statisticalAttr_id] =
            row["gg_" + this.statisticalAttr_id];
        }
      }
      if (this.cond.hierarchy_id) {
        this.goodsDetailForm.hierarchy_id = this.cond.hierarchy_id;
      }
      this.goodsDetailForm.page_size = 3000;
      this.goodsDetailForm.goods_type = row.goods_type;
      this.goodsDetailForm.refund_statistical_id = this.refund_statistical_id;
      this.goodsDetailForm.fixed = JSON.stringify(this.goodsDetailForm.fixed);
      this.goodsDetailForm.custom = JSON.stringify(this.goodsDetailForm.custom);
      downloadList({ ...this.goodsDetailForm }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download", `退货统计明细.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
        this.goodsDetailForm = {
          page: 1,
          page_size: 50,
          fixed: {},
          custom: {},
          goods_type: null,
        };
      });
    },
    /*
     *   获取供应商下拉数据
     */
    getSupplierList() {
      getSupplierList().then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data;
        }
      });
    },
    /*
     *  获取款式下拉
     */
    getGoodsStyle() {
      this.goodsStyleKey = this.dimension.find(
        (item) => item.system_attr_id === 101
      ).attr_id;

      const data = {
        attr_id: this.dimension.find((item) => item.system_attr_id === 101)
          .attr_id,
        h_id: this.userInfo.hierarchy_id,
      };
      getAttrValueList(data).then((res) => {
        if (res.code === 1) {
          this.goodsStyleList = res.data;
        }
      });
    },
    /*
     *  获取品牌下拉
     */
    getGoodsBrand() {
      this.goodsBrandKey = this.edit_list_header.find(
        (item) => item.system_attr_id === 103
      ).attr_id;
      const data = {
        attr_id: this.edit_list_header.find(
          (item) => item.system_attr_id === 103
        ).attr_id,
        h_id: this.userInfo.hierarchy_id,
      };
      getAttrValueList(data).then((res) => {
        if (res.code === 1) {
          console.log(res.data);
          this.goodsBrandList = res.data;
        }
      });
    },
    /*
     *  获取仓库下拉数据
     */
    getWarehouseList() {
      const data = {};
      if (this.cond.hierarchy_id !== "0") {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      getWarehouseListAll(data).then((res) => {
        if (res.code === 1) {
          res.data = res.data.map((item) => {
            item.warehouse_name = item.warehouse_name + "（" + item.name + "）";
            return item;
          });
          this.wareHouseList = res.data;
        }
      });
    },
    /*
     *  获取材质成色下拉数据
     */
    getMaterialColorList() {
      this.materialKey = this.edit_list_header.find(
        (item) => item.system_attr_id === 100
      ).attr_id;
      getMaterialListReq().then((res) => {
        if (res.code === 1) {
          this.materialList = res.data;
        }
      });
    },
    handleExportDetail(type, item, index) {
      const data = {
        refund_statistical_id: this.refund_statistical_id,
        goods_type: this.detaildedType || "",
        fixed: {},
        custom: {},
      };
      if (this.cond.day_time) {
        data.day_time = this.cond.day_time / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      if (type === 1) {
        if (this.detaildedForm.page) {
          data.page = this.detaildedForm.page;
        }
        if (this.detaildedForm.page_size) {
          data.page_size = 3000;
        }
      } else {
        this.exportIndex = index + 1;
        this.exportLoading = true;
        this.exportDisable = true;
        data.page = item.page;
        data.page_size = item.pageSize;
      }
      if (this.detaildedForm.goods_type_id) {
        data.fixed.goods_type_id = this.detaildedForm.goods_type_id;
      }
      if (this.detaildedForm.supplier_id) {
        data.fixed.supplier_id = this.detaildedForm.supplier_id;
      }
      if (this.detaildedForm.warehouse_id) {
        data.fixed.warehouse_id = this.detaildedForm.warehouse_id;
      }
      if (this.goodsStyleKey !== 0 && this.detaildedForm[this.goodsStyleKey]) {
        data.custom[this.goodsStyleKey] =
          this.detaildedForm[this.goodsStyleKey];
      }
      if (this.goodsBrandKey !== 0 && this.detaildedForm[this.goodsBrandKey]) {
        data.custom[this.goodsBrandKey] =
          this.detaildedForm[this.goodsBrandKey];
      }
      if (this.materialKey !== 0 && this.detaildedForm[this.materialKey]) {
        data.custom[this.materialKey] = this.detaildedForm[this.materialKey];
      }

      data.fixed = JSON.stringify(data.fixed);
      data.custom = JSON.stringify(data.custom);
      downloadList(data).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        if (type === 2) {
          a.setAttribute("download", `退货统计明细` + item.text + `.xlsx`);
        } else {
          a.setAttribute("download", `退货统计明细.xlsx`);
        }
        a.href = URL.createObjectURL(blob);
        a.click();
        this.exportLoading = false;
        this.exportDisable = false;
      });
    },
    /*
     *  数据分析表格
     */
    totalStatisticalData2D() {
      this.printData.list = [];
      this.loading = true;
      const data = {
        refund_statistical_id: this.refund_statistical_id,
        dimension: this.tabPosition,
        dimension_vice:
          this.statisticalAttr_id === "0" ? "" : this.statisticalAttr_id,
      };

      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      if (this.cond.day_time) {
        data.day_time = this.cond.day_time / 1000;
      }
      totalStatisticalData2D(data).then((res) => {
        if (res.code === 1) {
          this.loading = false;
          this.statisticalData = res.data.result_deal;
          this.header = res.data.header;
          this.printData.list = res.data.result_deal;
          this.list_header.unshift({
            is_set: 1,
            field_alias: res.data.header[0].header_id,
            field_text: res.data.header[0].name,
            width: 25,
          });
          this.$set(this.statisticalData);
        }
      });
    },
    handleExport() {
      this.exportLoading = true;
      const data = {
        refund_statistical_id: this.refund_statistical_id,
        dimension: this.tabPosition,
        dimension_vice:
          this.statisticalAttr_id === "0" ? "" : this.statisticalAttr_id,
      };

      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      if (this.cond.day_time) {
        data.day_time = this.cond.day_time / 1000;
      }
      totalStatisticalData2DDownload(data).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download", `退货统计.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
        this.exportLoading = false;
      });
    },
    /*
     *  获取退货统计默认或设置项_货品
     */
    getStatistical() {
      this.isDetails = false;
      this.isAdd = false;
      let arr = [];
      const data = {
        refund_statistical_id: this.refund_statistical_id,
      };
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      if (this.cond.day_time) {
        data.day_time = this.cond.day_time / 1000;
      }
      getStatistical(data).then((res) => {
        if (res.code === 1) {
          this.info = res.data.statistical;
          this.dimension = res.data.dimension;
          this.edit_list_header = res.data.list_header;
          this.time_type = res.data.statistical.conditions.time_type;
          this.statistical_set_header = res.data.statistical_header.filter(
            (item) => item.is_set === 1
          );
          var list = JSON.parse(JSON.stringify(res.data.statistical_header));
          this.list_header = list.map((item) => {
            item.field_alias = item.attr_id;
            item.field_text = item.attr_name;
            item.width = Number(item.width);
            return item;
          });

          this.list_header = this.list_header.filter(
            (item) => item.is_set === 1
          );
          this.list_header_temporary = JSON.parse(
            JSON.stringify(this.list_header)
          );
          this.tabPosition = String(
            res.data.dimension.filter((item) => item.is_set === 1)[0].attr_id
          );
          arr = JSON.parse(JSON.stringify(res.data.dimension));
          this.statistical_header = arr.filter(
            (item) =>
              item.attr_id !==
              res.data.dimension.filter((items) => items.is_set === 1)[0]
                .attr_id
          );
          this.cond = {
            day_time: res.data.day_time * 1000,
            hierarchy_id: String(res.data.hierarchy_id),
          };
          this.totalStatisticalData2D();
          this.getTotalStatisticalList();
          this.getGoodsStyle();
          this.getWarehouseList();
          this.getMaterialColorList();
          this.getGoodsBrand();
        }
      });
    },
    /*
     *  获取门店数据
     */
    getMerchants() {
      getHierarchyAll({
        hierarchy_id: storage.get("userInfo").hierarchy_ids,
      }).then((res) => {
        if (res.code === 1) {
          if (this.userInfo.hierarchy_type_id === 30) {
            res.data.unshift({ hierarchy_id: 0, name: "全部门店" });
          }
          this.storeList = res.data;
        }
      });
    },
    handleAllSearch() {
      this.totalStatisticalData2D();
      this.getTotalStatisticalList();
    },
    goBack() {
      this.$emit("hide");
    },
    handleDimensionType() {
      this.statisticalAttr_id = "0";
      var arr = JSON.parse(JSON.stringify(this.dimension));
      this.statistical_header = arr.filter(
        (item) => String(item.attr_id) !== this.tabPosition
      );
      this.list_header = [];
      this.list_header = this.list_header_temporary;
      this.totalStatisticalData2D();
    },
    handleStatisticType() {
      this.totalStatisticalData2D();
    },
    handleSetStatisticalHead() {
      const data = {
        refund_statistical_id: this.refund_statistical_id,
        name: this.info.name,
        time_type: this.info.conditions.time_type,
        dimension: this.dimension,
        statistical_header: this.statistical_set_header,
        list_header: this.edit_list_header,
      };
      editStatistical(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "设置成功!",
          });
          this.statistVisiable = false;
          this.getStatistical();
        }
      });
    },
    /*
     *  获取品类下拉数据
     */
    getTypeList() {
      const data = {};
      if (this.detaildedType !== "") {
        data.type = this.detaildedType;
      }
      getTypeListReq(data).then((res) => {
        if (res.code === 1) {
          this.goodsTypeList = res.data;
        }
      });
    },
    /*
     *  明细
     */
    getTotalStatisticalList() {
      const data = {
        refund_statistical_id: this.refund_statistical_id,
        goods_type: this.detaildedType || "",
        fixed: {},
        custom: {},
      };

      if (this.cond.day_time) {
        data.day_time = this.cond.day_time / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      if (this.detaildedForm.page) {
        data.page = this.detaildedForm.page;
      }
      if (this.detaildedForm.page_size) {
        data.page_size = this.detaildedForm.page_size;
      }
      if (this.detaildedForm.goods_type_id) {
        data.fixed.goods_type_id = this.detaildedForm.goods_type_id;
      }
      if (this.detaildedForm.supplier_id) {
        data.fixed.supplier_id = this.detaildedForm.supplier_id;
      }
      if (this.detaildedForm.warehouse_id) {
        data.fixed.warehouse_id = this.detaildedForm.warehouse_id;
      }
      if (this.goodsStyleKey !== 0 && this.detaildedForm[this.goodsStyleKey]) {
        data.custom[this.goodsStyleKey] =
          this.detaildedForm[this.goodsStyleKey];
      }
      if (this.goodsBrandKey !== 0 && this.detaildedForm[this.goodsBrandKey]) {
        data.custom[this.goodsBrandKey] =
          this.detaildedForm[this.goodsBrandKey];
      }
      if (this.materialKey !== 0 && this.detaildedForm[this.materialKey]) {
        data.custom[this.materialKey] = this.detaildedForm[this.materialKey];
      }

      data.fixed = JSON.stringify(data.fixed);
      data.custom = JSON.stringify(data.custom);
      this.tableLoading = true;
      getTotalStatisticalList(data).then((res) => {
        if (res.code === 1) {
          if (res.data.list.length !== 0) {
            this.detailedLastList = res.data.list.data.slice(-1);
            this.detailedList = res.data.list.data.filter(
              (item) => item.refund_order_number !== "当前页合计"
            );

            this.detailedHead = res.data.list.header;
          } else {
            this.detailedList = [];
          }
          this.tableLoading = false;
          this.detaildedTotal = res.data.count;
        } else {
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(val) {
      this.detaildedForm.page = val;
      this.getTotalStatisticalList();
    },
    handleSizeChange(val) {
      this.detaildedForm.page_size = val;
      this.getTotalStatisticalList();
    },
    handleCurrentChange_check(val) {
      this.goodsDetailForm.page = val;
      this.handleCheackGoodsDetail();
    },
    handleSizeChange_check(val) {
      this.goodsDetailForm.page_size = val;
      this.handleCheackGoodsDetail();
    },
    /*
     * 品类下拉取值
     */
    getValue(value) {
      const form = { ...this.detaildedForm };
      form.goods_type_id = value;
      this.detaildedForm = form;
      this.$nextTick(() => {
        const dom = document
          .getElementById("select")
          .getElementsByTagName("input")[0];
        this.text = dom.value;
      });
      this.getTotalStatisticalList();
    },
    /*
     *   当前页合计
     */
    getNowSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      if (this.detailedLastList.length !== 0) {
        columns.forEach((column, index) => {
          this.detailedLastList.forEach((item) => {
            sums[index] = item[column.property] || "--";
          });
        });
      }
      return sums;
    },
    getNowSummariesDetails(param) {
      const { columns, data } = param;
      const sums = [];
      if (this.goodsDetailLastList.length !== 0) {
        columns.forEach((column, index) => {
          this.goodsDetailLastList.forEach((item) => {
            sums[index] = item[column.property] || "--";
          });
        });
      }
      return sums;
    },
    /*
     *  关闭下载弹窗
     */
    cancelExport() {
      this.exportVisiable = false;
      this.exportList = [];
    },
    /*
     *  导出
     */
    handleLoad() {
      if (this.detaildedTotal > 3000) {
        const pageNum = Math.ceil(this.detaildedTotal / 3000);
        for (let i = 1; i <= pageNum; i++) {
          const info = {
            pageSize: 3000,
            page: i,
            text: 3000 * (i - 1) + 1 + "~" + 3000 * i + "条",
          };
          this.exportList.push(info);
        }
        this.exportVisiable = true;
      } else {
        this.handleExportDetail(1);
      }
    },
    handleDetailGoodsType(type) {
      this.detaildedType = type;
      this.detaildedForm = {
        page: 1,
        page_size: 50,
      };
      this.getTypeList();
      this.getTotalStatisticalList();
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  display: flex;
  /deep/ .el-form-item {
    margin-bottom: 00px;
  }
}
.tab {
  display: flex;
  padding: 0 18px;
  margin: 0 18px;
  height: 58px;
  background: linear-gradient(0deg, #f3f9ff 1%, #ffffff 100%);
  border: 1px solid #e8e8e8;
  border-radius: 6px 6px 0px 0px;
  line-height: 58px;

  .tabBox {
    position: relative;
    padding: 0 30px;

    .label {
      position: absolute;
      bottom: 0;
      left: 41%;
      width: 18px;
      height: 4px;
      opacity: 1;
      background: #2791ff;
      border-radius: 2px;
    }
  }
  .tabBox:hover {
    cursor: pointer;
  }
}
.trabsTitle {
  display: flex;
  padding-left: 18px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  .trabsSpan {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
  .title {
    display: flex;
    flex-direction: row;
    .span {
      margin: auto 10px auto 0;
      width: 4px;
      height: 16px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
}
.tab {
  display: flex;
  padding: 0 18px;
  margin: 0 18px;
  height: 58px;
  background: linear-gradient(0deg, #f3f9ff 1%, #ffffff 100%);
  border: 1px solid #e8e8e8;
  border-radius: 6px 6px 0px 0px;
  line-height: 58px;

  .tabBox {
    position: relative;
    padding: 0 30px;

    .label {
      position: absolute;
      bottom: 0;
      left: 41%;
      width: 18px;
      height: 4px;
      opacity: 1;
      background: #2791ff;
      border-radius: 2px;
    }
  }
  .tabBox:hover {
    cursor: pointer;
  }
}
/deep/ .other .el-table tr {
  height: 30px !important;
}
</style>
